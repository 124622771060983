import React from 'react';
import kaplanLogo from './kaplan.svg';
import ceviriLogo from './buluttamakineçevirisi.svg';
import './App.css';

function App() {
  return (
    <div className="App">
        <main>
            <a href="https://kaplan.pro">
                <div id="kaplan">
                    <h4>Translation Tools</h4>
                    <img src={kaplanLogo} alt="Kaplan logo" height="256px" width="256px"/>
                </div>
            </a>
            <a href="https://ceviri.net.tr">
                <div id="ceviri">
                    <h4>Machine Translation (beta)</h4>
                    <img src={ceviriLogo} alt="Ceviri logo" height="256px" width="256px"/>
                </div>
            </a>
        </main>
    </div>
  );
}

export default App;
